// scripts to innerpages
// if ($('#page__x').length) {}
if ($('#page__contato').length) {
    $("#formContato").validate({
        submitHandler: function (form) {
            //SUBMIT form
            $(form).ajaxSubmit({
                //target: 'status-envio',
                beforeSubmit: function () {
                    $('#formContato .c-stsSend').removeClass('u-dnone');
                    $('#formContato .c-stsSend__feedback--load').removeClass('u-dnone');
                    $('#formContato  #btnContato').attr('disabled', true);
                },
                success: function (result) {
                    result = result.split('|');
                    if(parseInt(result[1]) == 1){
                        $('#formContato .c-stsSend__feedback').addClass('u-dnone');
                        $('#formContato .c-stsSend__feedback--success').removeClass('u-dnone');
                        setTimeout(function(){
                            $('#formContato #btnContato').attr('disabled',false);
                            $("#formContato .field").val('');
                            $("#formContato textarea").val('').html('');
                            $("#formContato .field").removeClass('error');
                            $('#formContato .c-stsSend').addClass('u-dnone');
                            $('#formContato .c-stsSend__feedback').addClass('u-dnone');
                        },2000);
                    }else{
                        $('#formContato .c-stsSend__feedback').addClass('u-dnone');
                        $('#formContato .c-stsSend__feedback--error').removeClass('u-dnone');
                        $('#formContato .c-stsSend__feedback--error span').html(result[2]);
                        setTimeout(function () {
                            $('#formContato .c-stsSend').addClass('u-dnone');
                            $('#formContato .c-stsSend__feedback').addClass('u-dnone');
                            $('#formContato #btnContato').attr('disabled', false);
                        }, 3000);
                    }
                }
            });
        }
    });
    $(document).on('change','#formContato_estado',function(){
        var uf = $(this).val();
        $("#formContato_cidade").html('<option>Carregando cidades...</option>');
        $.ajax({
            type: "POST",
            data: {uf: uf},
            url: "ajax-cidades.php",
            success: function (result) {
                $("#formContato_cidade").html(result);
            }
        });
    });
}

if ($('#page__dermato').length) {
    // sticky menu
    $(window).on('scroll', function () {stickyPosition('.u-stickyAside', 30, get__navHeight() + 30);});
    $(window).on('load', function () {stickyPosition('.u-stickyAside', 30, get__navHeight() + 30);});

    // scrolled links
    $('.dermato__aside a').on('click', function(event) {
        event.preventDefault();
        var finalDestiny = $(this).attr('href');
        console.log(finalDestiny);
        goToSection__scroll($(finalDestiny), 0, 0, 700, 10);
    });

    //nav menu control
    $('.dermato__nav a').on('click', function(event) {
        event.preventDefault();
        var selectedArea = $(this).attr('href');
        $('.dermato__nav a').removeClass('is-active');
        $(this).addClass('is-active');
        switch (selectedArea) {
            case '#clinica' :
                $('.dermato__content').addClass('u-dnone');
                $('.dermato__content.dermato__clinica').addClass('u-dflex');
                $('.dermato__content.dermato__clinica').removeClass('u-dnone');
                break;
            case '#estetica' :
                $('.dermato__content').addClass('u-dnone');
                $('.dermato__content.dermato__estetica').addClass('u-dflex');
                $('.dermato__content.dermato__estetica').removeClass('u-dnone');
                break;
            case '#cirurgica' :
                $('.dermato__content').addClass('u-dnone');
                $('.dermato__content.dermato__cirurgica').addClass('u-dflex');
                $('.dermato__content.dermato__cirurgica').removeClass('u-dnone');
                break;
        }
        var scrollDestiny = '.dermato__nav';
        goToSection__scroll(scrollDestiny, 120, 110, 700, 10);
    });

    // open content hash
    switch (window.location.hash) {
        case '#clinica' :
            $('.dermato__content').addClass('u-dnone');
            $('.dermato__content.dermato__clinica').addClass('u-dflex');
            $('.dermato__content.dermato__clinica').removeClass('u-dnone');
            $('.dermato__nav .dermato__clinica a').addClass('is-active');
            break;
        case '#estetica' :
            $('.dermato__content').addClass('u-dnone');
            $('.dermato__content.dermato__estetica').addClass('u-dflex');
            $('.dermato__content.dermato__estetica').removeClass('u-dnone');
            $('.dermato__nav .dermato__estetica a').addClass('is-active');
            break;
        case '#cirurgica' :
            $('.dermato__content').addClass('u-dnone');
            $('.dermato__content.dermato__cirurgica').addClass('u-dflex');
            $('.dermato__content.dermato__cirurgica').removeClass('u-dnone');
            $('.dermato__nav .dermato__cirurgica a').addClass('is-active');
            break;
    }
}